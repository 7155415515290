import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import NewChips from '../common/NewChips';
import PasswordIcon from '../../../public/static/images/new_search/icons/icon-password.svg';
import CalendarIcon from '../../../public/static/images/new_search/icons/icon-calendar.svg';
import MoonIcon from '../../../public/static/images/new_search/icons/icon-moon.svg';
import { setCurrentState } from '../../../actions';

const linkStyles = { padding: '8px 10px', color: '#2f1f19' };

const linkData = t => [
  {
    id: 'buy-link',
    as: '/baki/alqi-satqi',
    href: '/[...search]?url=/alqi-satqi',
    icon: PasswordIcon,
    text: t('new_search.sell'),
    stat: 'new-search-buy-button',
    leased: 'false',
    paid_daily: undefined
  },
  {
    id: 'rent-link',
    as: 'baki/kiraye?paid_daily=false',
    href: '/[...search]?url=/kiraye?paid_daily=false',
    icon: CalendarIcon,
    text: t('new_search.rent'),
    stat: 'new-search-rent-button',
    leased: 'true',
    paid_daily: ['false']
  },
  {
    id: 'paid_daily-link',
    as: 'baki/kiraye?paid_daily=true',
    href: '/[...search]?url=/kiraye?paid_daily=true',
    icon: MoonIcon,
    text: t('search.form.paid_daily'),
    stat: 'new-search-paid-daily-button',
    leased: 'true',
    paid_daily: ['true']
  }
];

const SelectTabs = ({ change, values, submit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        'new-search__chips-wrapper',
        'new-search__tabs-wrapper'
      )}
    >
      {linkData(t).map(link => (
        <Link
          key={link.id}
          id={link.id}
          as={link.as}
          className="new-search__link"
          href={link.href}
        >
          <NewChips
            type="button"
            cypress="tab-link"
            style={linkStyles}
            icon={link.icon}
            borderRadius="14px"
            text={link.text}
            stat={link.stat}
            onClick={() => {
              change('category_id', undefined);
              change('leased', link.leased);
              change('paid_daily', link.paid_daily);
              dispatch(
                setCurrentState({
                  ...values,
                  category_id: undefined,
                  leased: link.leased,
                  paid_daily: link.paid_daily
                })
              );
              submit();
            }}
          />
        </Link>
      ))}
    </div>
  );
};

SelectTabs.propTypes = {
  values: PropTypes.object,
  change: PropTypes.func,
  submit: PropTypes.func
};

export default SelectTabs;
